var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body_section"},[_c('profileHeader',{attrs:{"showFilter":false,"showGoback":true}}),_c('div',{staticClass:"body_content"},[_c('div',{staticClass:"main_content"},[(_vm.filingDetails)?_c('div',{staticClass:"user_details_header"},[_c('figure',[_c('img',{attrs:{"src":_vm.checkProperty(_vm.filingDetails.clientDetails, 'logo')},on:{"error":function($event){return _vm.setAltImg($event)}}})]),_c('div',{staticClass:"user_details"},[_c('label',[_vm._v(_vm._s(_vm.checkProperty(_vm.filingDetails.clientDetails, "busName"))),_c('span',[_vm._v(" "+_vm._s(_vm.checkProperty(_vm.filingDetails, "stateDetails", "name"))+" ")])]),_c('ul',[_c('li',[_c('a',[_vm._m(0),_vm._v(_vm._s(_vm.checkProperty( _vm.filingDetails.clientDetails.busContact, "email" )))])]),_c('li',[_c('a',[_vm._m(1),_vm._v(_vm._s(_vm.checkProperty( _vm.filingDetails.clientDetails.busContact, "phoneCode" ))+" "+_vm._s(_vm.checkProperty( _vm.filingDetails.clientDetails.busContact, "phone" )))])])]),_c('p',[_vm._v(" Filing Period :"),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.checkProperty(_vm.filingDetails, "filingStartDate"))))]),_c('em',[_vm._v("to")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.checkProperty(_vm.filingDetails, "filingEndDate"))))])])])]):_vm._e(),_c('div',{staticClass:"form_section add_note"},[_c('ValidationObserver',{ref:"addNoteForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Date of Filing")]),_c('div',{staticClass:"input-group date",attrs:{"id":"datefiling","data-date-format":"dd-mm-yyyy"}},[_c('ValidationProvider',{attrs:{"name":"Filing Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"disabledDates":_vm.disabledDates,"maxDate":new Date(),"max-date":new Date(),"required":true,"format":"yyyy-MM-dd","name":"uniquename"},model:{value:(_vm.Payload.filedDate),callback:function ($$v) {_vm.$set(_vm.Payload, "filedDate", $$v)},expression:"Payload.filedDate"}}),_c('span',{staticClass:"input-group-addon"},[_c('i',{staticClass:"glyphicon glyphicon-calendar"})]),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Tax")]),_c('input',{staticClass:"form-control",staticStyle:{"background-color":"#efefef !important"},attrs:{"disabled":"","type":"number"},domProps:{"value":_vm.taxAmount}})])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Amount Paid")]),_c('ValidationProvider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.filedAmount),expression:"Payload.filedAmount"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.Payload.filedAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "filedAmount", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Difference Amount")]),_c('ValidationProvider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.adjustmentAmount),expression:"adjustmentAmount"}],staticClass:"form-control",attrs:{"disabled":true,"type":"number"},domProps:{"value":(_vm.adjustmentAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.adjustmentAmount=$event.target.value}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Remarks")]),_c('ValidationProvider',{attrs:{"name":"Remarks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.comment),expression:"Payload.comment"}],staticClass:"form-textarea",attrs:{"rows":"4"},domProps:{"value":(_vm.Payload.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "comment", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('span',{staticClass:"devider"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Reciept")]),_c('div',{staticClass:"upload_sec relative"},[_c('uploadFile',{attrs:{"allowMultiple":false,"acceptedFiles":'*',"takeIndex":false,"payLoadKey":'documents',"payLoadDocumentsKey":'documents'},on:{"onUploadComplete":_vm.onUploadComplete}})],1),_c('ul',{staticClass:"uploaded-list"},[_vm._l((_vm.Payload.documents),function(other,indx){return [_c('li',{key:indx},[_vm._v(" "+_vm._s(other.name)+" "),_c('span',{on:{"click":function($event){return _vm.removeItem(indx, _vm.Payload.documents)}}},[_vm._v("X")])])]})],2)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"primary_btn",attrs:{"disabled":_vm.checkForm},on:{"click":function($event){return _vm.addNote()}}},[_vm._v(" Submit ")])])])])])])],1)]),_c('footerView',{ref:"rout_view"})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('em',[_c('img',{attrs:{"src":require("@/assets/images/message1.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('em',[_c('img',{attrs:{"src":require("@/assets/images/phone.svg")}})])}]

export { render, staticRenderFns }