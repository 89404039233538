<template>
  <div>
    <footer>
      <p>©{{ currentYear }} Your TaxHero. inc</p>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  components: {},
  data: () => ({
    currentYear: null,
  }),
  mounted() {
    let date = new Date();
    this.currentYear = date.getFullYear();
  },
};
</script>
